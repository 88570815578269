/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import config from '../helpers/config';

const { publicRuntimeConfig } = config;
const { NODE_ENV, __WEBPACK__ } = publicRuntimeConfig;

let ctx;
let environmentContentData;

if (NODE_ENV !== 'test') {
  if (__WEBPACK__) {
    ctx = (require as any).context('../content', false, /\.json$/);
    environmentContentData = ctx(`./${NODE_ENV}.json`);
  } else {
    environmentContentData = require(`../content/${NODE_ENV}.json`);
  }
}

export const CmsContent = types
  .model('CmsContent', {
    content: types.maybe(types.map(types.frozen())),
  })
  .actions(self => {
    const loadContent = (): void => {
      self.content = environmentContentData;
    };

    const getValue = (key: string): any => {
      const value = self.content && self.content.get(key);
      // if (NODE_ENV === 'development') {
      //   console.log(`content key for ${key}:`, value);
      // }

      return value;
    };

    return {
      getValue,
      loadContent,
    };
  })
  .actions(self => {
    const afterCreate = (): void => {
      self.loadContent();
    };

    return {
      afterCreate,
    };
  })
  .views(self => {
    return {
      get FULL_CONTENT() {
        return self.content;
      },
    };
  });
