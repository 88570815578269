/* eslint-disable no-param-reassign, no-restricted-syntax, consistent-return */
import { getRoot, flow, types, Instance, addDisposer } from 'mobx-state-tree';
import { parseCookies, setCookie } from 'nookies';
import * as Sentry from '@sentry/node';
import { reaction } from 'mobx';
import QS from 'qs';
import { Store } from '../store';

import { ISignupFormData } from '../../../types/signup';
import { REFERRER_COOKIE, USER_SESSION_COOKIE } from '../../helpers/constants';
import {
  TRACKER_CATEGORY_CREATE_ACCOUNT,
  TRACKER_CATEGORY_FORGOT_PASSWORD,
  TRACKER_CATEGORY_LOGIN,
} from '../../helpers/trackerConstants';

const MONTH_OFFSET = 3;
const HALF_YEAR_OFFSET = 15552000000;
const KEY_SUBSTR_VALUE = 4;

export const Session = types
  .model('Session', {
    city: types.maybe(types.frozen()),
    isInitialising: types.boolean,
    token: types.maybe(types.string),
    user: types.maybe(types.frozen()),
    referrer: types.maybe(types.frozen()),
  })
  .views(self => ({
    get isActive(): boolean {
      return self.user && self.user.active;
    },
    get disableGeolocation(): boolean {
      return self.user && self.user.disableGeolocation;
    },
    get hideCommunity(): boolean {
      return self.user && self.user.hideCommunity;
    },
    get hideEarnings(): boolean {
      return self.user && self.user.hideEarnings;
    },
    get hideReferral(): boolean {
      return self.user && self.user.hideReferral;
    },
    get hideStatements(): boolean {
      return self.user && self.user.hideStatements;
    },
    get isLoggedIn(): boolean {
      return !!self.user;
    },
    get hasVerifiedEmail(): boolean {
      return self.user && self.user.verifiedEmail;
    },
    get hasSignedLatestAgreement(): boolean {
      return self.user && self.user.signedLatestAgreement;
    },
    get applicationStatus(): string {
      return (self.user && self.user.application && self.user.application.status) || null;
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    get worker(): any {
      // TODO we need to actually type the worker response from hero-gateway
      /* eslint-enable @typescript-eslint/no-explicit-any */
      return self.user;
    },
  }))
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;
    const { tracker } = rootStore;

    const setToken = (token: string): void => {
      self.token = token;
    };

    const getUser = flow(function* getUser() {
      self.isInitialising = true;
      const url = self.token ? `me?sessionIdentifier=${self.token}` : 'me';

      try {
        const res = yield rootStore.sdk.getRequest(url, {});
        const data = yield res.json();

        if (data.worker) {
          self.user = data.worker;
        }

        if (data.city) {
          self.city = data.city;
        }
        self.isInitialising = false;
        return data;
      } catch (err) {
        tracker.track('Loading user for current session failed', {
          category: 'User session',
        });
        self.isInitialising = false;
        console.error('Failed to load USER ', err);
      }
    });

    const getReferrer = flow(function* getReferer(code: string) {
      const url = `/hero-referrer/${code}`;

      try {
        const res = yield rootStore.sdk.getRequest(url, {});
        const data = yield res.json();

        if (data.worker) {
          self.referrer = data.worker;
        }

        return data;
      } catch (err) {
        tracker.track('Loading referrer failed', {
          category: 'User session',
        });

        console.error('Failed to load REFERRER ', err);
      }
    });

    const setReferrerCookie = (referrerCode: string): void => {
      const now = new Date();
      setCookie(null, REFERRER_COOKIE, referrerCode, {
        path: '/',
        expires: new Date(now.getFullYear(), now.getMonth() + MONTH_OFFSET, now.getDate()), // set the cookie for 3 months
      });
    };

    const signup = flow(function* signup(data: ISignupFormData, query: { referer?: string }) {
      const { personalDetails } = data;

      const cookies = parseCookies();
      const utm = cookies && cookies.utm && JSON.parse(decodeURIComponent(cookies.utm));
      const utmFirst = cookies && (cookies._umUTM_first || (utm && utm.first_seen)); // eslint-disable-line no-underscore-dangle
      const utmLast = cookies && (cookies._umUTM_last || (utm && utm.seen)); // eslint-disable-line no-underscore-dangle

      const requestData = {
        city_id: data.city,
        email: personalDetails.email.value,
        password: personalDetails.password.value,
        mobile: personalDetails.mobileNumber.value,
        name:
          personalDetails.legalName.value ||
          `${personalDetails.firstName.value} ${personalDetails.lastName.value}`,
        acceptedTermsAndConditions: true,
        acceptedMarketingCommunications: !!data.lastAcceptedMarketingCommunicationsAt,
        referralCode: personalDetails.referralCode.value,
        tracking: {
          firstUTMSeen: utmFirst ? JSON.parse(utmFirst) : null,
          lastUTMSeen: utmLast ? JSON.parse(utmLast) : null,
          httpReferrer: query && query.referer,
        },
      };

      try {
        const res: Response = yield rootStore.sdk.postRequest('/v2/signup', requestData, {});
        const responseData = yield res.json();
        tracker.track('Submit signup success', {
          category: TRACKER_CATEGORY_CREATE_ACCOUNT,
          data: (responseData && responseData.worker && responseData.worker.id) || null,
        });

        return responseData;
      } catch (error) {
        tracker.track('Submit signup error', {
          category: TRACKER_CATEGORY_CREATE_ACCOUNT,
          message: error,
        });

        console.error('Failed to signup', error);
      }
    });

    const signin = flow(function* signin(data) {
      const requestData = {
        email: data.email,
        password: data.password,
      };

      try {
        const res: Response = yield rootStore.sdk.postRequest('/login', requestData, {});
        const responseData = yield res.json();

        return responseData;
      } catch (error) {
        tracker.track('Login failed!', {
          category: TRACKER_CATEGORY_LOGIN,
          message: error,
        });
      }
    });

    const remindPassword = flow(function* remindPassword(data) {
      try {
        const res: Response = yield rootStore.sdk.postRequest(
          '/hero/password-reset/request',
          data,
          {
            useApiBase: true,
          },
        );

        const responseData = yield res.json();

        return responseData;
      } catch (error) {
        tracker.track('Password reset failed!', {
          category: TRACKER_CATEGORY_FORGOT_PASSWORD,
          message: error,
        });
      }
    });

    const resendEmailVerification = flow(function* resendEmailVerification() {
      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/resend-email-verification${QS.stringify(
            {
              sessionIdentifier: self.token,
            },
            {
              addQueryPrefix: true,
            },
          )}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }

        const responseData = yield res.json();

        tracker.track('Send Email Confirmation Success', {
          category: 'agreement',
        });

        return responseData;
      } catch (error) {
        tracker.track('Send Email Confirmation Failed', {
          category: 'agreement',
          message: error ? error.message : null,
        });

        throw error;
      }
    });

    const setUserSession = (session: string): void => {
      const now = new Date();
      setCookie(null, USER_SESSION_COOKIE, session, {
        path: '/',
        expires: new Date(now.getFullYear(), now.getMonth() + MONTH_OFFSET, now.getDate()), // set the cookie for 3 months
      });
    };

    const setUTMCookies = (): void => {
      function transformToAssocArray(parametersString: string): { [key: string]: string } {
        const params = {};
        const parametersArray = parametersString.split('&');
        for (let i = 0; i < parametersArray.length; i += 1) {
          const hParameters = parametersArray[i].split('=');
          const [firstParam, secondParam] = hParameters;
          params[firstParam] = secondParam;
        }

        return params;
      }

      function getSearchParameters(): { [key: string]: string } {
        const parametersString = window.location.search && window.location.search.substr(1);
        return parametersString ? transformToAssocArray(parametersString) : {};
      }

      // setup utm tag tracking
      // stick utm tags in cookie _umUTM_first and _umUTM_last
      let utmData = null;

      const params = getSearchParameters();
      for (const key in params) {
        if (key.substr(0, KEY_SUBSTR_VALUE) === 'utm_') {
          // eslint-disable-line no-magic-numbers
          if (!utmData) {
            utmData = {};
          }
          utmData[key] = params[key];
        }
      }

      if (utmData) {
        const expires = new Date(new Date().getTime() + HALF_YEAR_OFFSET);

        const utmStringified = JSON.stringify(utmData);
        const cookies = parseCookies();
        /* eslint-disable no-underscore-dangle */
        if (!(cookies && cookies._umUTM_first)) {
          /* eslint-enable no-underscore-dangle */
          // no first point of contact cookie set, set it now
          setCookie(null, '_umUTM_first', utmStringified, { path: '/', expires });
        }

        // always set last point of contact cookie regardless
        setCookie(null, '_umUTM_last', utmStringified, { path: '/', expires });
      }
    };

    const getUTMlastDecoded = (): { [key: string]: string } => {
      const cookies = parseCookies();
      /* eslint-disable no-underscore-dangle */
      return (cookies && cookies._umUTM_last && JSON.parse(cookies._umUTM_last)) || {};
      /* eslint-enable no-underscore-dangle */
    };

    const login = (data: { token: string }): void => {
      const { token } = data;
      setUserSession(token);
      setToken(token);
      getUser();
    };

    const logout = (): void => {
      Sentry.configureScope(scope => scope.setUser({ id: null }));
      setUserSession(null);
      setToken(undefined);
    };

    const getReferrerCookie = (): string => {
      const cookies = parseCookies();
      return cookies && cookies[REFERRER_COOKIE];
    };

    const verifyEmail = flow(function* verifyEmail(OTLToken) {
      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/one-time-login${QS.stringify(
            {
              token: OTLToken,
            },
            {
              addQueryPrefix: true,
            },
          )}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }
        const responseData = yield res.json();
        login(responseData);

        tracker.track('Email Verification Success', {
          category: 'verify-email',
        });
      } catch (error) {
        tracker.track('Email Verification Failed', {
          category: 'verify-email',
          message: error,
        });

        throw error;
      }
    });

    const oneTimeLogin = flow(function* oneTimeLogin(OTLToken) {
      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/one-time-login${QS.stringify(
            {
              token: OTLToken,
            },
            {
              addQueryPrefix: true,
            },
          )}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }
        const responseData = yield res.json();
        login(responseData);

        tracker.track('One time login success', {
          category: 'onetimelogin',
        });
      } catch (error) {
        tracker.track('One time login failure', {
          category: 'onetimelogin',
          message: error,
        });

        throw error;
      }
    });

    const afterAttach = (): void => {
      const identifyReactionDisposer = reaction(
        () => ({ id: self.user && self.user.id }),
        () => {
          const { user } = self;
          if (user) {
            if (user.id) {
              tracker.identify(user);
              Sentry.configureScope(scope => scope.setUser({ id: self.user.id }));
            } else {
              tracker.identify(null);
              Sentry.configureScope(scope => scope.setUser({ id: null }));
            }
            return;
          }

          tracker.identify(null);
        },
        {
          name: 'Set user scope and identify on load',
          fireImmediately: true,
        },
      );

      // Make sure we destroy our reaction listener instances
      addDisposer(self, identifyReactionDisposer);

      const cookies = parseCookies() || {};

      if (typeof window !== 'undefined') {
        setUTMCookies();
      }

      const { userSession = null } = cookies;

      // Check user token is valid
      if (userSession && userSession !== 'null') {
        setToken(userSession);
        getUser();
      }
    };

    return {
      afterAttach,
      getReferrer,
      getReferrerCookie,
      getUser,
      getUTMlastDecoded,
      login,
      logout,
      oneTimeLogin,
      remindPassword,
      resendEmailVerification,
      setReferrerCookie,
      setToken,
      setUTMCookies,
      signin,
      signup,
      verifyEmail,
    };
  });
