/* eslint-disable no-param-reassign */
import { getRoot, types, Instance } from 'mobx-state-tree';

import fetch from 'isomorphic-unfetch';
import { parseCookies } from 'nookies';

import config from '../helpers/config';
import { Store } from './store';
import i18n from '../server/i18n';

const { publicRuntimeConfig } = config;

const isServer = typeof window === 'undefined';

const { API_BASE, HERO_GATEWAY_BASE, HERO_GATEWAY_APPLICATION_KEY } = publicRuntimeConfig;

function performRequest(ctx, url, options: any): Promise<Response> {
  if (url[0] !== '/') {
    url = `/${url}`;
  }

  const cookies = parseCookies(ctx);
  let utmCookie;

  if (cookies) {
    utmCookie = cookies.utm;
  }

  // TODO: reserch for better way for invalidating cache
  const requestTime = new Date().getTime();
  let cacheKillerAddition;
  if (url.indexOf('?') !== -1) {
    cacheKillerAddition = `&__tt=${requestTime}`;
  } else {
    cacheKillerAddition = `?__tt=${requestTime}`;
  }
  url += cacheKillerAddition;

  const opts = {
    credentials: 'same-origin',
    ...options,
    headers: {
      ...options.headers,
      'X-Application-Capabilities': '3ds',
      'X-Application-Identifier': HERO_GATEWAY_APPLICATION_KEY,
      'X-Accept-Language':
        (options && options.headers && options.headers['X-Accept-Language']) ||
        i18n.i18n.language ||
        '',
      'X-User-Agent': ctx.userAgent,
      'X-UTM-Attribution': isServer ? '' : utmCookie,
      'X-Request-Time': parseInt((new Date().getTime() / 1000).toString(), 10),
    },
  };

  if (ctx.session && ctx.session.token) {
    opts.headers['x-session-identifier'] = ctx.session.token;
  }

  return fetch(`${options.useApiBase ? API_BASE : HERO_GATEWAY_BASE}${url}`, opts);
  // TODO there's a missing status check here - should be implemented rather than relyin on every route to handle
  // Once we test bookings list with e2e tests then add in checks here and ensure nothing is broken by that change
}

export const SDK = types.model('SDK', {}).actions(self => {
  const rootStore = getRoot<Instance<typeof Store>>(self);

  const getUrl = url => {
    if (url[0] !== '/') {
      url = `/${url}`;
    }
    const sep = url.indexOf('?') > -1 ? '&' : '?';
    return `/api${url}${sep}key=${HERO_GATEWAY_APPLICATION_KEY}`;
  };

  const getRequest = (url, options): Promise<Response> =>
    performRequest(rootStore, url, {
      ...options,
      method: 'GET',
    });

  const postRequest = (url, body, options): Promise<Response> =>
    performRequest(rootStore, url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options && options.headers),
      },
      body: JSON.stringify(body),
      method: 'POST',
    });

  const putRequest = (url, body, options): Promise<Response> =>
    performRequest(rootStore, url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options && options.headers),
      },
      body: JSON.stringify(body),
      method: 'PUT',
    });

  const deleteRequest = (url, body, options): Promise<Response> =>
    performRequest(rootStore, url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options && options.headers),
      },
      body: JSON.stringify(body),
      method: 'DELETE',
    });

  return {
    getUrl,
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
  };
});
