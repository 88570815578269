/* eslint-disable no-param-reassign */
import { getRoot, types, Instance } from 'mobx-state-tree';
import config from '../helpers/config';

import { Store } from './store';

const { publicRuntimeConfig } = config;
const { NODE_ENV, __WEBPACK__ } = publicRuntimeConfig;

let ctx;
let environmentConfigData;

if (NODE_ENV !== 'test') {
  if (__WEBPACK__) {
    ctx = (require as any).context('../config', false, /\.json$/);
    environmentConfigData = ctx(`./${NODE_ENV}.json`);
  } else {
    environmentConfigData = require(`../config/${NODE_ENV}.json`);
  }
}

export const Config = types
  .model('Config', {
    configData: types.maybe(types.array(types.frozen())),
    lastCity: types.maybe(types.string),
    lastCountry: types.maybe(types.string),
  })
  .actions(self => {
    const loadConfig = (): void => {
      self.configData = environmentConfigData;
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const getValue = (key: string, filterCountry?: string, filterCity?: string): any => {
      /* eslint-enable @typescript-eslint/no-explicit-any */
      if (filterCountry && !filterCity) {
        self.lastCountry = filterCountry;

        const value = (self as ConfigType).latestCountryConfig[key];
        // if (NODE_ENV === 'development') {
        //   console.log(`config key for ${key} ${filterCountry}:`, value);
        // }
        return value;
      }

      if (filterCountry && filterCity) {
        self.lastCountry = filterCountry;
        self.lastCity = filterCity;

        // If an invalid city id is passed we should default to the region to prevent crashes
        let value = (self as ConfigType).latestCountryConfig[key];
        if ((self as ConfigType).latestCityConfig) {
          value = (self as ConfigType).latestCityConfig[key];
        }
        // if (NODE_ENV === 'development') {
        //   // If we had to default to the city we can show that message here
        //   console.log(
        //     `config key for ${key} ${filterCountry} ${
        //       (self as ConfigType).latestCityConfig ? filterCity : 'invalid city found'
        //     }:`,
        //     value,
        //   );
        // }
        return value;
      }

      const value = (self as ConfigType).currentContextConfig[key];
      // if (NODE_ENV === 'development') {
      //   console.log(`config key for ${key}:`, value);
      // }
      // If we haven't specified extra filters we can use the default context config
      return value;
    };

    return {
      getValue,
      loadConfig,
    };
  })
  .views(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    return {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      get FULl_CONFIG(): any {
        /* eslint-enable @typescript-eslint/no-explicit-any */
        return self.configData;
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      get currentContextConfig(): any {
        /* eslint-enable @typescript-eslint/no-explicit-any */
        const { configData } = self;
        const { i18nUtils } = rootStore;
        if (i18nUtils.umRegion && self.configData) {
          const [regionConf] = configData.filter(
            conf => conf.country_id === i18nUtils.umRegion && conf.city_id === null,
          );
          if (regionConf) {
            return regionConf.keyValues;
          }
        }

        // If there's no region set, return UK as default
        return configData.filter(conf => conf.country_id === 'UK')[0].keyValues;
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      get latestCountryConfig(): any {
        /* eslint-enable @typescript-eslint/no-explicit-any */
        const { i18nUtils } = rootStore;
        const { configData, lastCountry } = self;

        if (i18nUtils.umRegion && self.configData) {
          const [regionConf] = configData.filter(
            conf => conf.country_id === lastCountry && conf.city_id === null,
          );
          if (regionConf) {
            return regionConf.keyValues;
          }
        }

        // If there's no region set, return UK as default
        return configData.filter(conf => conf.country_id === 'UK')[0].keyValues;
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      get latestCityConfig(): any {
        /* eslint-enable @typescript-eslint/no-explicit-any */
        const { i18nUtils } = rootStore;
        const { configData, lastCity, lastCountry } = self;

        if (i18nUtils.umRegion && self.configData) {
          const [regionConf] = configData.filter(
            conf => conf.country_id === lastCountry && conf.city_id === lastCity,
          );
          if (regionConf) {
            return regionConf.keyValues;
          }
        }

        return null;
      },
    };
  });

type ConfigType = Instance<typeof Config>;
