/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import config from '../helpers/config';

const { publicRuntimeConfig } = config;
const { DESKTOP_MIN_WIDTH } = publicRuntimeConfig;

export const Screen = types
  .model('Screen', {
    height: types.maybe(types.number),
    width: types.maybe(types.number),
    supportsWebp: types.boolean,
  })
  .actions(self => ({
    setSupportsWebp(value): void {
      self.supportsWebp = value;
    },

    resize(): void {
      self.width = window.innerWidth || document.body.clientWidth;
      self.height = window.innerHeight || document.body.clientHeight;
    },
  }))
  .views(self => ({
    get isDesktop(): boolean {
      return self.width >= DESKTOP_MIN_WIDTH;
    },
  }));
