/* eslint-disable no-param-reassign, no-restricted-syntax */
import { types } from 'mobx-state-tree';

export const Selection = types
  .model('Selection', {
    city: types.maybe(types.string),
    email: types.maybe(types.string),
    rf: types.maybe(types.string),
  })
  .actions(self => ({
    loadQuery(query): void {
      for (const key of Object.keys(self)) {
        let value = self[key];
        value = query[key] ? `${query[key]}` : undefined;
        if (value !== self[key]) {
          self[key] = value;
        }
      }
    },
  }));
