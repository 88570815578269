/* eslint-disable no-param-reassign */
import { Instance, flow, getRoot, types, cast } from 'mobx-state-tree';

import { utcToZonedTime } from 'date-fns-tz';
import isBefore from 'date-fns/isBefore';

import { Store } from './store';
import { SingleBookingItem } from './SingleBookingItem';

export const SingleBooking = types
  .model('SingleBooking', {
    booking: types.maybeNull(SingleBookingItem),
    isBookingNotFound: types.optional(types.boolean, false),
    isConfirming: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, true),
    isError: types.optional(types.boolean, false),
    isUpdating: types.optional(types.boolean, false),
    isUpdateError: types.optional(types.boolean, false),
  })
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    const confirmBooking = flow(function* confirmSingleBooking(): any {
      self.isConfirming = true;

      const url = rootStore.session.token
        ? `bookings/${self.booking.id}?sessionIdentifier=${rootStore.session.token}`
        : `bookings/${self.booking.id}`;

      try {
        const res = yield rootStore.sdk.putRequest(url, {
          isConfirmed: true,
        });
        const data: {
          booking: Instance<typeof SingleBookingItem>;
        } = yield res.json();

        if (!res.ok) {
          throw data;
        }

        self.booking = data.booking;
        self.isConfirming = false;

        return self.booking.isConfirmed;
      } catch (error) {
        console.error(`Failed to confirm BOOKING ID: ${self.booking.id}`, error);
        self.isConfirming = false;
      }
    });

    const loadBooking = flow(function* getBooking(id: number): any {
      self.isError = false;
      self.isLoading = true;
      const url = rootStore.session.token
        ? `bookings/${id}?sessionIdentifier=${rootStore.session.token}`
        : `bookings/${id}`;

      try {
        const res = yield rootStore.sdk.getRequest(url, {});

        const data: {
          booking?: Instance<typeof SingleBookingItem>;
          code?: string;
          success: boolean;
        } = yield res.json();

        //TODO: Use error state like in video site to handle errors
        if (data.code === 'ORDER_NOT_FOUND') {
          self.isBookingNotFound = true;
          self.isLoading = false;

          return null;
        }

        self.booking = cast(data.booking);
        self.isLoading = false;

        return data.booking;
      } catch (err) {
        console.error(`Failed to load BOOKING ID: ${id}`, err);
        self.isError = true;
        self.isLoading = false;
      }
    });

    const updateBooking = flow(function* updateSingleBooking(body): any {
      self.isUpdating = true;
      self.isUpdateError = false;

      const url = rootStore.session.token
        ? `bookings/${self.booking.id}?sessionIdentifier=${rootStore.session.token}`
        : `bookings/${self.booking.id}`;

      try {
        const res = yield rootStore.sdk.putRequest(url, body);
        const data = yield res.json();

        if (!res.ok) {
          throw data;
        }

        self.booking = cast(data.booking);

        self.isUpdating = false;

        return data;
      } catch (error) {
        console.error(`Failed to update BOOOKING ID: ${self.booking.id}`, error);
        self.isUpdateError = true;
        self.isUpdating = false;
      }
    });

    const clearIsUpdateError = (): void => {
      self.isUpdateError = false;
    };

    return {
      confirmBooking,
      clearIsUpdateError,
      loadBooking,
      updateBooking,
    };
  })
  .views(self => ({
    get isPastBooking(): boolean {
      const { booking } = self;

      if (!booking) return false;

      const endDate = utcToZonedTime(new Date(booking.endsAt), booking.timezone);

      return isBefore(endDate, new Date());
    },
    get isCancelled(): boolean {
      return self.booking ? self.booking.isCancelled : false;
    },
  }));
