/* eslint-disable no-param-reassign */
import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { Store } from './store';

import { TRACKER_CATEGORY_CALL_TO_GATEWAY } from '../helpers/trackerConstants';

const Article = types
  .model('Article', {
    content: types.maybe(types.string),
    id: types.maybe(types.maybeNull(types.string)),
    title: types.maybe(types.string),
  })
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    const getArticle = flow(function* getArticle(slug: string): any {
      try {
        const res = yield rootStore.sdk.getRequest(`/faqs/articles/${slug}`, {});
        const data = yield res.json();

        self.title = data.article.title;
        self.content = data.article.content;
      } catch (error) {
        console.error('Failed to load ARTICLE ', error);
        rootStore.tracker.track('Failed to load ARTICLE in article store', {
          category: TRACKER_CATEGORY_CALL_TO_GATEWAY,
          error,
        });
      }
    });

    return {
      getArticle,
    };
  });

const ArticlesList = types
  .model('ArticlesList', {
    articles: types.optional(types.array(Article), [
      {
        content: undefined,
        id: undefined,
        title: undefined,
      },
    ]),
  })
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    const getArticles = flow(function* getArticles(slugs: string[], language: string): any {
      try {
        const res = yield Promise.all(
          slugs.map(slug =>
            rootStore.sdk.getRequest(`/faqs/articles/${slug}`, {
              headers: {
                'X-Accept-Language': language || '',
              },
            }),
          ),
        );
        const data = yield Promise.all(res.map(i => i.json()));

        self.articles = data.map(i => {
          return {
            content: i.article.content,
            id: `${i.article.id}`,
            title: i.article.title,
          };
        });

        return self.articles;
      } catch (error) {
        console.error('Failed to load ARTICLES ', error);
        rootStore.tracker.track('Failed to load ARTICLES in articles store', {
          category: TRACKER_CATEGORY_CALL_TO_GATEWAY,
          error,
        });
      }
    });

    return {
      getArticles,
    };
  });

export { Article, ArticlesList };
