import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import { Provider } from 'mobx-react';
import { getSnapshot, Instance } from 'mobx-state-tree';

// SplitIo
import { SplitSdk, SplitFactory } from '@splitsoftware/splitio-react';

import * as Sentry from '@sentry/node';

import { parseCookies, setCookie } from 'nookies';
import uuidv4 from 'uuid/v4';
import ClientSplitDestroy from '../components/SplitIo/ClientSplitDestroy';
import config from '../helpers/config';
import { appWithTranslation, withTranslation, WithTranslationType } from '../server/i18n';

import { initializeStore, Store } from '../stores/store';
import { StoreProvider } from '../contexts/storeContext';
import HeadTags from '../components/HeadTags';

import '../styles/main.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    attachEvent(event: string, listener: EventListener): boolean;
    detachEvent(event: string, listener: EventListener): void;
    _laq: any;
    analytics: any;
    liveagent: any;
    liveAgentDeployment: any;
    matchMedia: (query: string) => MediaQueryList;
  }
}
/* eslint-enable */

const isServer = typeof window === 'undefined';

const { publicRuntimeConfig } = config;
// Replace below for split io browser packages
const { NODE_ENV, SENTRY_DSN, SPLIT_IO_API_BROWSER_KEY, RELEASE_VERSION } = publicRuntimeConfig;
// const { NODE_ENV, SENTRY_DSN, RELEASE_VERSION } = publicRuntimeConfig;

if (SENTRY_DSN) {
  Sentry.init({
    enabled: NODE_ENV !== 'development',
    environment: NODE_ENV,
    dsn: SENTRY_DSN,
    release: RELEASE_VERSION,
    ignoreErrors: [
      // HeyTap browser from chinese android webview
      'getReadModeConfig',
      'getReadModeRender',
      'getReadModeExtract',
      /^Error: No error message$/,
      /^UnhandledRejection: Non-Error promise rejection captured with value: $/,
      'Illegal invocation',
      /Object Not Found Matching Id:/,
    ],
  });
}

class MyApp extends App<WithTranslationType> {
  store: Instance<typeof Store>;
  splitFactory: SplitIO.ISDK;
  splitKey: string;

  static async getInitialProps({
    Component,
    ctx,
  }): Promise<{
    initialState: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    isServer: boolean;
    namespacesRequired: string[];
    pageProps: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }> {
    const store = initializeStore(isServer);

    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx, store);
    }

    return {
      initialState: getSnapshot(store),
      isServer,
      namespacesRequired: ['chat', 'cookie-banner'],
      pageProps,
    };
  }

  constructor(props, ctx) {
    super(props);

    // Initialise the store
    this.store = initializeStore(props.isServer, props.initialState);

    // Load the config from manage from the json file
    this.store.config.loadConfig(NODE_ENV);

    if (typeof window !== 'undefined') {
      // Get sessionID
      let sessionId =
        window.sessionStorage &&
        typeof window.sessionStorage.getItem === 'function' &&
        window.sessionStorage.getItem('umSessionID');

      if (!sessionId) {
        sessionId = uuidv4();
        window.sessionStorage.setItem('umSessionID', sessionId);
      }

      this.store.tracker.setSessionId(sessionId);

      // Track first page hit on the site
      this.store.tracker.page({
        path: window.location.pathname,
        url: window.location.href,
      });

      // Event listener to check when the initial load is complete
      window.addEventListener
        ? window.addEventListener(
            'load',
            () => {
              this.store.setInitialLoadComplete(true);
            },
            false,
          )
        : window.attachEvent &&
          window.attachEvent('onload', () => {
            this.store.setInitialLoadComplete(true);
          });

      // Event listener to check screen size to update screen store
      if (window.addEventListener) {
        window.addEventListener('resize', this.store.screen.resize);
        window.addEventListener('orientationchange', this.store.screen.resize);
      } else if (window.attachEvent) {
        window.attachEvent('resize', this.store.screen.resize);
        window.attachEvent('orientationchange', this.store.screen.resize);
      }

      this.store.screen.resize();

      // Check if browser supports WEBP image format
      const webp = new window.Image();
      webp.onerror = (): void => {
        this.store.screen.setSupportsWebp(false);
      };
      webp.onload = (): void => {
        this.store.screen.setSupportsWebp(true);
      };
      webp.src =
        'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoBAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';

      this.store.setUserAgent(window.navigator && window.navigator.userAgent);
    }

    // Get the domain cookies
    const cookies = parseCookies(ctx);

    // Get the device identifier cookie
    let identifierCookie;
    let utmCookie;
    this.splitKey = 'anon';

    if (cookies) {
      identifierCookie = cookies.umDeviceIdentifier;
      utmCookie = cookies.utm;
      this.splitKey = cookies.umDeviceIdentifier;
    }

    // If there isn't a cookie with the uuid for umDeviceIdentifier, we then need to create one
    if (!identifierCookie) {
      identifierCookie = uuidv4();
      this.splitKey = identifierCookie;

      setCookie(ctx, 'umDeviceIdentifier', identifierCookie, {
        path: '/',
      });
    }

    this.store.setUmDeviceIdentifier(identifierCookie);

    // TODO note ive moved this back from optional chaining as ESLINT needs to be udpated first to support this in its new parser
    if (this.store && this.store.session && this.store.session.user && this.store.session.user.id) {
      this.splitKey = this.store.session.user.id;
    }

    if (!isServer) {
      this.splitFactory = SplitSdk({
        core: {
          authorizationKey: SPLIT_IO_API_BROWSER_KEY,
          key: `${this.splitKey}`,
        },
      });
    }

    // Get the utm data cookie and write info in store
    if (utmCookie) {
      this.store.setUtmCookie(JSON.parse(utmCookie));
    }

    // Track page hit when route changes
    Router.events.on('onRouteChangeComplete', this.routeChangeHandler);

    if (this.props.i18n && this.props.i18n.language) {
      this.store.i18nUtils.setLocale(this.props.i18n.language);
    }
  }

  componentWillUnmount(): void {
    Router.events.off('onRouteChangeComplete', this.routeChangeHandler);
  }

  routeChangeHandler(_url: string): void {
    this.store.tracker.page({
      path: _url,
      url: _url,
    });
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;

    // err is not exposed as a type on AppProps - we need to take advantage of it for the bug defined here https://github.com/vercel/next.js/issues/8592
    const { err } = this.props as any; // eslint-disable-line @typescript-eslint/no-explicit-any

    const content = (
      <Provider store={this.store}>
        <StoreProvider value={this.store}>
          <HeadTags />
          <Component {...pageProps} err={err} />
        </StoreProvider>
      </Provider>
    );

    // Don't load split on the server
    if (!isServer) {
      return (
        <SplitFactory factory={this.splitFactory}>
          <ClientSplitDestroy splitUserKey={this.splitKey}>{content}</ClientSplitDestroy>
        </SplitFactory>
      );
    }

    return content;
  }
}

export default appWithTranslation(withTranslation()(MyApp));
