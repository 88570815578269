/* eslint-disable no-param-reassign */
import { getRoot, flow, types, Instance } from 'mobx-state-tree';
import config from '../helpers/config';
import { Store } from './store';

const { publicRuntimeConfig } = config;
const { DEFAULT_CITIES } = publicRuntimeConfig;

const City = types.model('City', {
  country_id: types.maybe(types.string),
  heroLanguage_id: types.maybe(types.maybeNull(types.string)),
  id: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.string),
});

const Views = (self: any) => ({
  get citiesNamesList(): string[] {
    return self.cities.map((city: Instance<typeof City>) => city.name);
  },

  get citiesByCountry(): Instance<typeof City>[] {
    const rootStore = getRoot(self) as Instance<typeof Store>;
    const countryId = rootStore.i18nUtils.umRegion;

    return self.cities.filter(
      (city: Instance<typeof City>) => city.country_id === countryId && city.id !== null,
    );
  },

  get defaultCity(): Instance<typeof City> {
    const rootStore = getRoot(self) as Instance<typeof Store>;
    const defaultCities = DEFAULT_CITIES;
    const countryId = rootStore.i18nUtils.umRegion;

    return self.cities
      .filter((city: Instance<typeof City>) => city.country_id === countryId)
      .find((city: Instance<typeof City>) => city.id === defaultCities[countryId]);
  },

  get selectDefaultCity(): boolean {
    const { citiesByCountry = [] } = self;

    return citiesByCountry.length === 1;
  },
});

const CitiesList = types
  .model('CitiesList', {
    cities: types.optional(types.array(City), [
      {
        country_id: undefined,
        heroLanguage_id: undefined,
        id: undefined,
        name: undefined,
      },
    ]),
  })
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    const getCities = flow(function* getCities(): any {
      try {
        const res = yield rootStore.sdk.getRequest('cities', {});
        const data = yield res.json();

        self.cities = data.cities;
      } catch (err) {
        console.error('Failed to load CITIES ', err);
      }
    });

    return {
      getCities,
    };
  })
  .views(Views);

export { City, CitiesList };
