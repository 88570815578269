/* eslint-disable no-param-reassign, no-restricted-syntax, consistent-return */
import { getRoot, flow, types, Instance } from 'mobx-state-tree';
import QS from 'qs';
import { Store } from '../store';

export const PartnershipAgreement = types
  .model('PartnershipAgreement', {
    docUrl: types.maybe(types.string),
  })
  .views(self => ({
    get hellosignDocUrl(): string {
      return self.docUrl;
    },
  }))
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;
    const { session, tracker } = rootStore;

    const getSignatureRequest = flow(function* getSignatureRequest() {
      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/signature-request${QS.stringify(
            {
              sessionIdentifier: session.token,
            },
            {
              addQueryPrefix: true,
            },
          )}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }

        const responseData = yield res.json();

        self.docUrl =
          responseData.signURL &&
          responseData.signURL.embedded &&
          responseData.signURL.embedded.sign_url;

        tracker.track('Get Partnership Agreement Success', {
          category: 'agreement',
        });

        return responseData;
      } catch (error) {
        tracker.track('Get Partnership Agreement Failed', {
          category: 'agreement',
          message: error && error.message,
        });

        throw error;
      }
    });

    return {
      getSignatureRequest,
    };
  });
