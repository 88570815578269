/* eslint-disable no-param-reassign */
import { applySnapshot, Instance, types } from 'mobx-state-tree';

import { Application } from './application';
import { Article, ArticlesList } from './articles';
import { BookingsList } from './BookingList';
import { SingleBooking } from './SingleBooking';
import { CitiesList } from './cities';
import { CmsContent } from './cmsContent';
import { Config } from './config';
import { I18nUtils } from './i18nUtils';
import { Screen } from './screen';
import { SDK } from './sdk';
import { Selection } from './selection';
import { PartnershipAgreement } from './session/partnership-agreement';
import { Session } from './session';
import { Tracker } from './tracker';

export const Store = types
  .model('Store', {
    anonymousId: types.maybe(types.string),
    application: types.maybe(Application),
    article: types.optional(Article, {
      content: undefined,
      title: undefined,
    }),
    articles: types.optional(ArticlesList, { articles: [] }),
    bookingsList: types.maybe(BookingsList),
    cities: types.optional(CitiesList, { cities: [] }),
    cmsContent: types.maybe(CmsContent),
    config: types.maybe(Config),
    i18nUtils: types.maybe(I18nUtils),
    initialLoadComplete: types.boolean,
    partnershipAgreement: types.optional(PartnershipAgreement, {
      docUrl: undefined,
    }),
    screen: types.optional(Screen, {
      height: undefined,
      supportsWebp: false,
      width: undefined,
    }),
    sdk: types.maybe(SDK),
    selection: types.maybe(Selection),
    session: types.optional(Session, {
      city: undefined,
      isInitialising: false,
      token: undefined,
      user: undefined,
      referrer: undefined,
    }),
    singleBooking: types.maybe(SingleBooking),
    tracker: types.optional(Tracker, {
      sequenceID: 0,
      sessionID: undefined,
    }),
    umDeviceIdentifier: types.maybe(types.string),
    userAgent: types.maybe(types.string),
    utmCookie: types.maybe(types.frozen()),
  })
  .actions(self => {
    const setAnonymousId = (value: string): void => {
      self.anonymousId = value;
    };

    const setInitialLoadComplete = (value: boolean): void => {
      self.initialLoadComplete = value;
    };

    const setUmDeviceIdentifier = (value: string): void => {
      self.umDeviceIdentifier = value;
    };

    const setUserAgent = (value: string): void => {
      self.userAgent = value;
    };

    const setUtmCookie = (value: string): void => {
      self.utmCookie = value;
    };

    return {
      setAnonymousId,
      setInitialLoadComplete,
      setUmDeviceIdentifier,
      setUserAgent,
      setUtmCookie,
    };
  });

const storeSnaphot = {
  anonymousId: undefined,
  application: {},
  article: {
    content: undefined,
    title: undefined,
  },
  bookingsList: {},
  articles: undefined,
  cities: undefined,
  cmsContent: {
    content: undefined,
  },
  config: {
    configData: undefined,
    lastCity: undefined,
    lastCountry: undefined,
  },
  i18nUtils: {
    locale: '',
    region: '',
    umRegion: '',
  },
  initialLoadComplete: false,
  partnershipAgreement: {
    docUrl: undefined,
  },
  screen: {
    height: undefined,
    supportsWebp: false,
    width: undefined,
  },
  sdk: {},
  selection: {
    city: undefined,
    email: undefined,
    rf: undefined,
  },
  session: {
    isInitialising: false,
    token: undefined,
    user: undefined,
    referrer: undefined,
  },
  singleBooking: {
    booking: null,
  },
  tracker: {
    sequenceID: 0,
    sessionID: undefined,
  },
  umDeviceIdentifier: undefined,
  userAgent: undefined,
  utmCookie: undefined,
};

export type StoreType = Instance<typeof Store>;
let store: StoreType = null as any;

export const initializeStore = (isServer: boolean, snapshot = null): StoreType => {
  if (isServer) {
    store = Store.create(storeSnaphot);
  }

  if ((store as any) === null) {
    store = Store.create(storeSnaphot);
  }

  if (snapshot) {
    applySnapshot(store, snapshot);
  }

  return store;
};
