/* eslint-disable no-param-reassign */
import { reaction } from 'mobx';
import { addDisposer, types } from 'mobx-state-tree';
import { setCookie } from 'nookies';
import { LEGACY_LANGUAGE_COOKIE } from '../helpers/constants';

export const I18nUtils = types
  .model('I18nUtils', {
    locale: types.string,
  })
  .views(self => ({
    get region(): string {
      if (self.locale) {
        const [, regionSplit] = self.locale.split('-');
        return regionSplit ? regionSplit.toUpperCase() : 'GB';
      }
      // safety default
      return 'GB';
    },
    get datesLocale(): string {
      if (self.locale) {
        const [language] = self.locale.split('-');

        if (language === 'fr') {
          return language;
        }

        return 'en-GB';
      }
      // safety default
      return 'en-GB';
    },
    get legacyLocale(): string {
      if (self.locale) {
        const [lang, region] = self.locale.split('-');

        return lang && region ? [lang, region.toUpperCase()].join('_') : 'en_GB';
      }

      // safety default
      return 'en_GB';
    },
  }))
  .views(self => ({
    get umRegion(): string {
      return self.region === 'GB' ? 'UK' : self.region;
    },
  }))
  .actions(self => {
    const setLocale = (value: string): void => {
      self.locale = value;
    };

    return {
      setLocale,
    };
  })
  .actions(self => {
    const afterAttach = (): void => {
      const setLanguageCookieReactionDisposer = reaction(
        () => self.locale,
        locale => {
          // Set the legacy language cookie so that we can handle the change between the new -> old site
          if (locale && typeof window !== 'undefined') {
            const now = new Date();
            setCookie(null, LEGACY_LANGUAGE_COOKIE, self.legacyLocale, {
              path: '/',
              expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()), // set the cookie for 1 year
            });
          }
        },
        {
          name: 'Set legacy locale cookies',
          fireImmediately: true,
        },
      );

      // Make sure we destroy our reaction listener instances
      addDisposer(self, setLanguageCookieReactionDisposer);
    };

    return {
      afterAttach,
    };
  });
