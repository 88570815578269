/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { BookingCurrency } from './BookingCurrency';

export const BookingListItem = types
  .model('BookingListItem', {
    address1: types.maybe(types.maybeNull(types.string)),
    address2: types.maybe(types.maybeNull(types.string)),
    addressType: types.maybe(types.maybeNull(types.string)),
    address_id: types.maybe(types.maybeNull(types.string)),
    cats: types.maybe(types.maybeNull(types.boolean)),
    city: types.maybe(types.maybeNull(types.string)),
    city_id: types.maybe(types.maybeNull(types.string)),
    corporateAccount_id: types.maybe(types.maybeNull(types.string)),
    corporateBooking_id: types.maybe(types.maybeNull(types.string)),
    createdAt: types.maybe(types.maybeNull(types.string)),
    currency: types.maybe(types.maybeNull(BookingCurrency)),
    dogs: types.maybe(types.maybeNull(types.boolean)),
    endsAt: types.maybe(types.maybeNull(types.string)),
    fee: types.maybe(types.maybeNull(types.number)),
    heroNote: types.maybeNull(types.string),
    id: types.maybe(types.identifierNumber),
    isCancelled: types.maybe(types.maybeNull(types.boolean)),
    isCheckedIn: types.maybe(types.maybeNull(types.boolean)),
    isCheckedOut: types.maybe(types.maybeNull(types.boolean)),
    isConfirmed: types.maybe(types.maybeNull(types.boolean)),
    isDigital: types.maybe(types.maybeNull(types.boolean)),
    items: types.maybe(types.maybeNull(types.frozen())), // TODO define from contracts
    lat: types.maybe(types.maybeNull(types.number)),
    lng: types.maybe(types.maybeNull(types.number)),
    orderNumber: types.maybe(types.maybeNull(types.number)),
    otherPets: types.maybe(types.maybeNull(types.boolean)),
    parking: types.maybe(types.maybeNull(types.string)),
    postcode: types.maybe(types.maybeNull(types.string)),
    quiet: types.maybe(types.maybeNull(types.boolean)),
    rating: types.maybe(types.maybeNull(types.number)),
    ratingComments: types.maybe(types.maybeNull(types.string)),
    requiresTreatmentReport: types.maybe(types.maybeNull(types.boolean)),
    serviceArea_id: types.maybe(types.maybeNull(types.string)),
    stairs: types.maybe(types.maybeNull(types.string)),
    startsAt: types.maybe(types.maybeNull(types.string)),
    timezone: types.maybe(types.maybeNull(types.string)),
    total: types.maybe(types.maybeNull(types.number)),
    type: types.maybe(types.maybeNull(types.string)),
    updatedAt: types.maybe(types.maybeNull(types.string)),
    user: types.maybe(types.maybeNull(types.frozen())),
    userComments: types.maybe(types.maybeNull(types.string)),
  })
  .actions(() => ({}))
  .views(self => ({
    get bookingItems(): [{ name: string; speciality: { name: string } }] {
      return self.items.filter(item => !item.next_id && !item.parent_id && item.type === 'product');
    },
  }))
  .views(self => ({
    get treatmentName(): string {
      if (self.type !== 'back-to-back') {
        return (self.bookingItems[0] && self.bookingItems[0].name) || null;
      }

      return self.bookingItems.map(bookingItem => bookingItem.speciality.name).join(' & ');
    },
  }));
