/* eslint-disable no-param-reassign */
import { getRoot, flow, types, Instance, cast } from 'mobx-state-tree';
import QS from 'qs';

import { utcToZonedTime } from 'date-fns-tz';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import sub from 'date-fns/sub';
import toDate from 'date-fns/toDate';

import { Store } from './store';

import { BookingListItem } from './BookingListItem';

interface IBookingListResponse {
  bookings: Instance<typeof BookingListItem>[];
  success: boolean;
}

export const BookingsList = types
  .model('BookingsList', {
    bookings: types.optional(types.array(BookingListItem), []),
    isError: types.optional(types.maybeNull(types.boolean), false),
    isLoading: types.optional(types.maybeNull(types.boolean), true),
  })
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;

    const loadBookings = flow(function* loadBookingsGenerator(): any {
      self.isLoading = true;
      self.isError = false;

      const subDate = sub(new Date(), {
        days: rootStore.config.getValue(
          'hero.bookings.history-threshold-days',
          rootStore.i18nUtils.umRegion,
        ),
      });

      const queryInfo = {
        startsAt: {
          $gte: toDate(subDate),
        },
      };

      const url = `bookings/?${QS.stringify({
        sessionIdentifier: rootStore.session.token,
        ...queryInfo,
      })}`;

      try {
        const res = yield rootStore.sdk.getRequest(url, {});
        const data: IBookingListResponse = yield res.json();

        if (!res.ok) {
          throw data;
        }

        if (data) {
          self.bookings = cast(data.bookings);
        }

        self.isLoading = false;

        return self.bookings;
      } catch (err) {
        console.error('Failed to load bookings', err);

        self.isError = true;
        self.isLoading = false;
      }
    });

    return {
      loadBookings,
    };
  })
  .views(self => ({
    get bookingsList(): Instance<typeof BookingListItem>[] {
      return self.bookings;
    },

    get pastBookings(): Instance<typeof BookingListItem>[] {
      return self.bookings.filter(booking => {
        const date = new Date(booking.endsAt);
        const zonedDate = utcToZonedTime(date, booking.timezone);

        return isBefore(zonedDate, new Date());
      });
    },

    get unconfirmedBookings(): Instance<typeof BookingListItem>[] {
      return self.bookings.filter(booking => {
        const date = new Date(booking.endsAt);
        const zonedDate = utcToZonedTime(date, booking.timezone);

        return isAfter(zonedDate, new Date()) && !booking.isConfirmed;
      });
    },

    get upcomingBookings(): Instance<typeof BookingListItem>[] {
      return self.bookings.filter(booking => {
        const date = new Date(booking.endsAt);
        const zonedDate = utcToZonedTime(date, booking.timezone);

        return isAfter(zonedDate, new Date());
      });
    },
  }))
  .views(self => ({
    get unconfirmedBookingsCount(): number {
      return self.unconfirmedBookings.length;
    },
  }));
