/* eslint-disable no-param-reassign */
import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { parseCookies, setCookie } from 'nookies';
import uuidv4 from 'uuid/v4';

import { Store } from './store';

declare global {
  interface Window {
    analytics: any;
  }
}

const isServer = typeof window === 'undefined';

export const Tracker = types
  .model('Tracker', {
    sequenceID: types.number,
    sessionID: types.maybe(types.string),
  })
  .actions(self => {
    const rootStore = getRoot<Instance<typeof Store>>(self);

    const ready = flow(function* ready() {
      if (isServer || typeof window.analytics === 'undefined') return;

      try {
        yield new Promise(resolve => window.analytics.ready(resolve));
        console.log('tracker#ready');
      } catch (err) {
        console.error('Failed to load analytics ', err);
      }
    });

    const track = flow(function* track(eventName: string, eventData) {
      const { umDeviceIdentifier } = rootStore;
      const { sessionID } = self;

      yield ready();

      if (isServer || typeof window.analytics === 'undefined') return;

      console.log('tracker#track', eventName, eventData);

      const localCounter = self.sequenceID;

      self.sequenceID += 1;

      const evData = {
        layer: 'web',
        ...eventData,
        hostname: document.location.hostname,
        path: document.location.pathname,
        sequenceID: localCounter,
        sessionID,
        umDeviceIdentifier,
      };

      const traits = window.analytics.user().traits();

      try {
        yield new Promise(resolve => {
          window.analytics.track(eventName, evData, { traits }, resolve);
        });
      } catch (err) {
        console.error('Failed to track event', err);
      }
    });

    const page = flow(function* page(properties) {
      yield ready();

      if (isServer || typeof window.analytics === 'undefined') return;

      console.log('tracker#page');

      const { umDeviceIdentifier } = rootStore;

      if (properties) {
        properties.sequenceID = self.sequenceID;
        properties.umDeviceIdentifier = umDeviceIdentifier;
        properties.sessionID = self.sessionID;
      }

      self.sequenceID += 1;

      const traits = window.analytics.user().traits();

      try {
        yield new Promise(resolve => window.analytics.page(properties, { traits }, resolve));
      } catch (err) {
        console.error('Failed to track event', err);
      }
    });

    const identify = flow(function* identify(user, ctx) {
      const cookies = parseCookies(ctx);
      let identifierCookie;

      if (cookies) {
        identifierCookie = cookies.umDeviceIdentifier;
      }

      // If there isn't a cookie with the uuid for umDeviceIdentifier, we then need to create one
      if (!identifierCookie) {
        identifierCookie = uuidv4();

        setCookie(ctx, 'umDeviceIdentifier', identifierCookie, {
          path: '/',
        });
      }

      rootStore.setUmDeviceIdentifier(identifierCookie);

      yield ready();

      if (isServer || typeof window.analytics === 'undefined') return;

      console.log('tracker#identify', user && user.id);

      let anonymousId = cookies['segment-anonymous-id'];

      if (!anonymousId) {
        const generatedAnonymousId = window.analytics.user().anonymousId();

        setCookie(ctx, 'segment-anonymous-id', generatedAnonymousId, {
          path: '/',
        });

        anonymousId = generatedAnonymousId;
      }

      rootStore.setAnonymousId(anonymousId);

      if (user) {
        window.analytics.identify(
          user.id,
          {
            name: user.name,
            email: user.email,
            umDeviceIdentifier: identifierCookie,
          },
          {
            anonymousId,
          },
        );
      } else {
        window.analytics.reset();
        window.analytics.identify(
          null,
          {
            umDeviceIdentifier: identifierCookie,
          },
          {
            anonymousId,
          },
        );
      }
    });

    const setSessionId = value => {
      self.sessionID = value;
    };

    return {
      identify,
      page,
      ready,
      setSessionId,
      track,
    };
  });
