import React, { useEffect, FC, ReactNode } from 'react';
import { useClient } from '@splitsoftware/splitio-react';

interface IClientSplitDestroyProps {
  children: ReactNode;
  splitUserKey: string | number;
}

const ClientSplitDestroy: FC<IClientSplitDestroyProps> = ({
  children,
  splitUserKey,
}: IClientSplitDestroyProps) => {
  const client = useClient(`${splitUserKey}`);

  useEffect(
    () => (): Promise<void> | void => {
      if (client) {
        client.destroy();
      }
    },
    [client],
  );

  return <React.Fragment>{children}</React.Fragment>;
};

export default ClientSplitDestroy;
