/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { BookingCurrency } from './BookingCurrency';

export interface IJoinCallUrl {
  endsAt: string;
  joinCallUrl: string;
  key: string;
  serviceName: string;
  startsAt: string;
}

const JoinCallUrl = types.model('JoinCallUrl', {
  endsAt: types.string,
  joinCallUrl: types.string,
  key: types.string,
  serviceName: types.string,
  startsAt: types.string,
});

const CorporateServiceData = types.model('CorporateServiceData', {
  deliveryType: types.maybe(
    types.enumeration('deliveryType', ['apartment', 'digital', 'room', 'webinar']),
  ),
  durationMins: types.maybe(types.number),
  id: types.maybe(types.string),
  name: types.maybe(types.string),
});

const CorporateBookingRoom = types.model('CorporateBookingRoom', {
  account_id: types.string,
  address_id: types.string,
  createdAt: types.string,
  deletedAt: types.maybeNull(types.string),
  id: types.string,
  isDefault: types.boolean,
  name: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  updatedAt: types.string,
});

const CorporateAvailableServiceData = types.model('CorporateAvailableServices', {
  appointment_id: types.string,
  room: types.maybeNull(types.union(CorporateBookingRoom, types.string)),
  roomInfo: types.maybeNull(types.string),
  room_id: types.maybeNull(types.string),
  service: types.optional(types.maybeNull(CorporateServiceData), {}),
});

const CorporateAttendee = types.model('CorporateAttendee', {
  id: types.string,
  user_id: types.maybeNull(types.number),
  profile_id: types.maybeNull(types.string),
  clientName: types.string,
});

const CorporateReservationData = types.model('CorporateReservationData', {
  attendees: types.array(CorporateAttendee),
  capacity: types.maybeNull(types.number),
  clientName: types.maybeNull(types.string),
  endsAt: types.string,
  id: types.string,
  isBreak: types.boolean,
  isGroup: types.boolean,
  location: types.maybeNull(types.string),
  profile_id: types.maybeNull(types.string),
  reservation_id: types.maybeNull(types.string),
  service: types.optional(types.maybeNull(CorporateServiceData), {}),
  startsAt: types.string,
  user_id: types.maybeNull(types.number),
  webinarLink: types.maybeNull(types.string),
});

const CorporateBookingData = types.model('CorporateBookingData', {
  availableServices: types.optional(
    types.maybeNull(types.array(CorporateAvailableServiceData)),
    [],
  ),
  reservations: types.optional(types.maybeNull(types.array(CorporateReservationData)), []),
  slotSizeMins: types.maybeNull(types.number),
});

export const SingleBookingItem = types
  .model('SingleBookingItem', {
    address1: types.maybe(types.maybeNull(types.string)),
    address2: types.maybe(types.maybeNull(types.string)),
    addressType: types.maybe(types.maybeNull(types.string)),
    address_id: types.maybe(types.maybeNull(types.string)),
    cancellationStatus: types.maybe(types.maybeNull(types.string)),
    cats: types.maybe(types.maybeNull(types.boolean)),
    city: types.maybe(types.maybeNull(types.string)),
    city_id: types.maybe(types.maybeNull(types.string)),
    corporateAccount_id: types.maybe(types.maybeNull(types.string)),
    corporateBooking_id: types.maybe(types.maybeNull(types.string)),
    corporateData: types.maybe(types.maybeNull(CorporateBookingData)),
    createdAt: types.maybe(types.maybeNull(types.string)),
    currency: types.maybe(types.maybeNull(BookingCurrency)),
    dogs: types.maybe(types.maybeNull(types.boolean)),
    endsAt: types.maybe(types.maybeNull(types.string)),
    fee: types.maybe(types.maybeNull(types.number)),
    heroNote: types.maybe(types.maybeNull(types.string)),
    id: types.maybe(types.number),
    isCancelled: types.maybe(types.maybeNull(types.boolean)),
    isCheckedIn: types.maybe(types.maybeNull(types.boolean)),
    isCheckedOut: types.maybe(types.maybeNull(types.boolean)),
    isConfirmed: types.maybe(types.maybeNull(types.boolean)),
    isDigital: types.maybe(types.maybeNull(types.boolean)),
    items: types.maybe(types.maybeNull(types.frozen())),
    joinCallUrls: types.maybe(types.maybeNull(types.array(JoinCallUrl))),
    joinVideoCallUrl: types.maybe(types.maybeNull(types.string)),
    lat: types.maybe(types.maybeNull(types.number)),
    lng: types.maybe(types.maybeNull(types.number)),
    noteHistory: types.maybe(types.maybeNull(types.frozen())),
    orderNumber: types.maybe(types.maybeNull(types.number)),
    otherHeroes: types.maybe(types.maybeNull(types.frozen())),
    otherPets: types.maybe(types.maybeNull(types.boolean)),
    parking: types.maybe(types.maybeNull(types.string)),
    postcode: types.maybe(types.maybeNull(types.string)),
    quiet: types.maybe(types.maybeNull(types.boolean)),
    rating: types.maybe(types.maybeNull(types.number)),
    ratingComments: types.maybe(types.maybeNull(types.string)),
    requiresTreatmentReport: types.maybe(types.maybeNull(types.boolean)),
    serviceArea_id: types.maybe(types.maybeNull(types.string)),
    stairs: types.maybe(types.maybeNull(types.string)),
    startsAt: types.maybe(types.maybeNull(types.string)),
    timezone: types.maybe(types.maybeNull(types.string)),
    total: types.maybe(types.maybeNull(types.number)),
    type: types.maybe(types.maybeNull(types.string)),
    updatedAt: types.maybe(types.maybeNull(types.string)),
    useCustomLinkForVideoCall: types.maybe(types.boolean),
    user: types.maybe(types.maybeNull(types.frozen())),
    userComments: types.maybe(types.maybeNull(types.string)),
  })
  .views(self => ({
    get filteredNotesHistory() {
      return self.noteHistory ? self.noteHistory.filter(note => note.booking_id === self.id) : [];
    },
    get bookingItems() {
      return self.items.filter(item => !item.next_id && !item.parent_id && item.type === 'product');
    },
    get orderedJoinCallUrls() {
      if (self.joinCallUrls && self.joinCallUrls.length) {
        return self.joinCallUrls.sort((callUrlA, callUrlB) => {
          if (callUrlA.startsAt > callUrlB.startsAt) return 1;
          if (callUrlA.startsAt < callUrlB.startsAt) return -1;
          return 0;
        });
      }

      return [];
    },
    get changeFees(): number {
      if (self.items && self.items.length) {
        return self.items.reduce(function(total: number, item) {
          return (item.isCancelled ? item.total - item.fee : 0) + total;
        }, 0);
      }

      return 0;
    },
    get currencySymbol(): string {
      return self.currency.textSymbol;
    },
  }))
  .views(self => ({
    get treatmentName(): string {
      if (self.type !== 'back-to-back') {
        return (self.bookingItems[0] && self.bookingItems[0].name) || null;
      }

      return self.bookingItems.map(bookingItem => bookingItem.speciality.name).join(' & ');
    },
    get bookingTotal(): number {
      return self.total - self.fee - self.changeFees;
    },
    get bookingTimezone(): string {
      return self.timezone;
    },
  }));
